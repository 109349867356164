<mat-menu class="light-theme" panelClass="light-theme">
<span cdkTrapFocus (keydown.tab)="$event.stopPropagation()" (keydown.shift.tab)="$event.stopPropagation()">
  <form class="filter-form"
        (submit)="addTag(filterText)"
        (keydown)="$event.stopPropagation()"
        (keyup.arrowDown)="createButtons.first ? createButtons.first.focus() : buttons.first?.focus()"
  >
    <mat-form-field
      class="w-100 no-bottom"
      hideRequiredMarker="true"
      appearance="fill"
    >
      <input
        #nameInput
        smClickStopPropagation
        matInput
        placeholder="Add Tag"
        name="filter"
        [(ngModel)]="filterText"
        class="filter"
        autocomplete="off"
      >
      <div matSuffix class="d-flex-center mx-2">
        <i *ngIf="companyTags !== null && (disableFilterByProject$ | async)=== false"
           class="icon sm-md me-3 mb-2 pointer"
           [class]="tagsFilterByProject ? 'i-project-tags-filter-active' : 'i-project-tags-filter'"
           smTooltip="Toggle all/project-only tags"
           data-id="tagFilterButton"
           (click)="projectTagsFilterToggle()"
           smClickStopPropagation></i>
        <i data-id="searchButton" class="al-icon sm-md me-3 mb-2" [class]="filterText? 'al-ico-dialog-x pointer':'al-ico-search'"
           (click)="filterText && clear()" smClickStopPropagation></i>
      </div>
    </mat-form-field>
  </form>
  <div class="buttons-container" [class.searching]="filterText">
    <button
      #tagCreateButton
      mat-menu-item
      class="item"
      *ngIf="filterText?.trim().length > 0 && !allTags?.includes(filterText)"
      (click)="addTag(filterText)"
      (keyup.arrowUp)="focus($event)"
    >"{{filterText.trim()}}" <span class="new" data-id="createNewTag">(Create New)</span></button>
    <button
      #tagButton
      mat-menu-item
      *ngFor="let tag of allTags | filter:tags | filter:filterText; let i=index; trackBy: trackByFn"
      class="item"
      (click)="addTag(tag)"
      (keyup.arrowUp)="i === 0 && !createButtons.first && focus($event)"
      [smTooltip]="tag"  smShowTooltipIfEllipsis
    >{{tag}}</button>
  </div>
  <hr>
  <button mat-menu-item data-id="TagColors" class="item" (click)="openTagColors()">Tag Colors</button>
</span>
</mat-menu>
