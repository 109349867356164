<sm-card class="project-card"
         (click)="projectClicked()"
         [isExample]="!['All Experiments'].includes(project.name) && !isRootProject && (!project.company || ! project.company.id)"
         [isFolder]="true"
         [oneTabMode]="!(project?.sub_projects?.length>0)"
         [subFolderTitle]="project?.sub_projects?.length + ' sub projects'"
>
  <div secondTab class="sub-projects-list">
    <cdk-virtual-scroll-viewport [itemSize]="30" [minBufferPx]="440" [maxBufferPx]="660" class="h-100">
      <a
        *cdkVirtualFor="let subProject of project?.sub_projects; trackBy: trackById"
        class="d-flex align-items-center"
        smClickStopPropagation
        (click)="subProjectClicked(subProject)"
        [smTooltip]="subProject?.name | cleanProjectPath "
        [innerHTML]="subProject?.name | cleanProjectPath | breadcrumbsEllipsis"></a>
    </cdk-virtual-scroll-viewport>
  </div>
  <div header-content class="d-flex justify-content-between align-items-center card-name">
    <sm-inline-edit #projectName
                    class="title edit-name dark"
                    [originalText]="project.name | shortProjectName"
                    [editable]="false"
                    [minLength]="2"
                    [required]="true"
                    pattern="^[^\/]{2,}$"
                    [forbiddenString]="projectsNames"
                    [inlineDisabled]="true"
                    (textChanged)="prepareProjectNameForChange($event)"
    >
      <span class="project-name" [smTooltip]="project.basename" smShowTooltipIfEllipsis>{{project.basename}}</span>
    </sm-inline-edit>
    <div *ngIf="project.system_tags?.includes('default')" class="justify-content-end project-tag">Sample</div>
  </div>
  <div class="d-flex justify-content-around w-100">
    <ng-content></ng-content>
  </div>
  <div footer>
    <ng-content select="[card-footer]"></ng-content>
  </div>
</sm-card>
