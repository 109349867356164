<div class="header-container" [class.login]="isLogin">
  <sm-breadcrumbs
    class="spacer"
    [class.flex-grow-1]="!(userFocus)"
    [class.user-focus]="userFocus "
    [class.share-view]="isShareMode"
    [activeWorkspace]="activeWorkspace">
  </sm-breadcrumbs>
  <div class="ms-3 d-flex align-items-center me-auto" *ngIf="userFocus">
    <sm-show-only-user-work></sm-show-only-user-work>
  </div>

  <div *ngIf="showLogo && !isLogin" class="logo-full middle"
       [class.make-room-for-slogan]="environment.whiteLabelSlogan">
    <img *ngIf="!environment.whiteLabelLogo" alt="logo" priority ngSrc="assets/logo-white.svg" width="122" height="42">
    <div *ngIf="environment.whiteLabelSlogan" class="slogan">{{environment.whiteLabelSlogan}}</div>
  </div>
  <div *ngIf="isLogin && environment.whiteLabelLogo" class="logo-full">
    <img alt="logo" priority ngSrc="assets/logo-white.svg" width="122" height="42">
  </div>
  <div *ngIf="isLogin" class="spacer"></div>

  <div class="right-buttons" data-id="rightSideHeaderpanel" >
    <sm-common-search #search [class.share-view]="isShareMode"></sm-common-search>

    <span class="d-flex pointer resources-trigger" [matMenuTriggerFor]="resourcesMenu">
      <i class="al-icon al-ico-help-outlined" data-id="help Icon"></i>
    </span>
    <span class="pointer menu-trigger position-relative" data-id="Avatar" [matMenuTriggerFor]="profileMenu">
      <img alt="avatar" class="avatar"  *ngIf="(user | async).avatar; else iconAvatar" [src]="(user | async).avatar">
      <ng-template #iconAvatar>
        <div class="user-icon">
          <i class="al-icon al-ico-account sm-md"></i>
        </div>
      </ng-template>
      <div *ngIf="userNotificationPath || (invitesPending$ | async)?.length" class="user-notification"></div>
    </span>

    <mat-menu #profileMenu="matMenu" class="user-menu">
      <button mat-menu-item [routerLink]="'settings/' + userNotificationPath" data-id="Settings Button">
        <i class="al-icon icon sm-md" [class]="userNotificationPath ? 'al-ico-settings-alert' : 'al-ico-settings'">
        </i>Settings
      </button>
      <sm-header-user-menu-actions></sm-header-user-menu-actions>
      <button mat-menu-item (click)="logout()" data-id="Logout">
        <i class="al-ico-logout al-icon icon sm-md"></i>Logout
      </button>
    </mat-menu>

    <mat-menu #resourcesMenu="matMenu" class="user-menu">
      <button mat-menu-item (click)="openWelcome($event)" data-id="Python Package setup Option">
        <i class="al-icon sm-md al-ico-code-file"></i>ClearML Python Package setup
      </button>
      <button mat-menu-item (click)="navigate('https://www.youtube.com/c/ClearML/featured')" data-id="Youtube Option">
        <i class="al-icon al-ico-youtube sm-md"></i>ClearML on Youtube
      </button>
      <button mat-menu-item (click)="navigate(environment.docsLink)" data-id="Online Documentation Option">
        <i class="al-icon sm-md al-ico-documentation"></i>Online Documentation
      </button>
      <button mat-menu-item (click)="openTip()" data-id="Pro Tips Option">
        <i class="al-icon sm-md al-ico-tips"></i>Pro Tips
      </button>
      <ng-container *smCheckPermission="'applications'">
        <button mat-menu-item
                *ngIf="$any(environment)?.appsYouTubeIntroVideoId && $any(environment).appAwarenessMenu !== false"
                (click)="openAppsAwareness($event, $any(environment)?.appsYouTubeIntroVideoId)" data-id="Apps Introduction Option"
        >
          <i class="al-icon sm-md al-ico-applications"></i>ClearML Apps Introduction
        </button>
      </ng-container>
      <button mat-menu-item (click)="navigate('mailto:support@clear.ml')" data-id="Contact Us">
        <i class="al-icon sm-md al-ico-email"></i>Contact Us
      </button>
    </mat-menu>
  </div>
</div>
<ng-content></ng-content>
