<div class="d-flex justify-content-between header-container align-items-center"
     [ngClass]="{'archive-mode': isArchived}">
  <div class="d-flex-center">
    <ng-container *ngTemplateOutlet="addButtonTemplate; context: {smallScreen: (isSmallScreen$ | async).matches}">
    </ng-container>
    <sm-toggle-archive
      [class.hide-item]="sharedView"
      [showArchived]="isArchived"
      [minimize]="(isSmallScreen$ | async).matches"
      (toggleArchived)="isArchivedChanged.emit($event)"
    ></sm-toggle-archive>
    <sm-button-toggle
      [disabled]="!tableMode"
      class="ms-3"
      [options]="[{label: 'Table view', value: 'table', icon: 'al-ico-table-view'}, {label: 'Details view', value: 'info', icon: 'al-ico-experiment-view'}]"
      [value]="tableMode"
      [rippleEffect]="rippleEffect"
      (valueChanged)="tableModeChanged.emit($event)"></sm-button-toggle>
  </div>
  <sm-project-context-navbar
    *ngIf="showNavbarLinks"
    [class.hide-item]="sharedView"
    [activeFeature]="'experiments'"
    [archivedMode]="isArchived"
  ></sm-project-context-navbar>


  <div class="d-flex justify-content-end align-items-center right-buttons">
    <sm-clear-filters-button
      *ngIf="!minimizedView"
      [tableFilters]="tableFilters"
      (clearTableFilters)="clearTableFilters.emit(tableFilters)"
    ></sm-clear-filters-button>
    <sm-menu class="download-btn" buttonClass="al-icon al-ico-download pointer lm" panelClasses="light-theme"
             [showCart]="false" smTooltip="Download table as CSV" [disabled]="noData">
      <sm-menu-item (itemClicked)="downloadTableAsCSV.emit()" itemLabel="Download on screen items"></sm-menu-item>
      <sm-menu-item (itemClicked)="downloadFullTableAsCSV.emit()"
                    [itemLabel]="'Download first '+ (maxDownloadItems$ | async) +' items'"></sm-menu-item>
    </sm-menu>

    <sm-experiment-custom-cols-menu
      *ngIf="!minimizedView"
      [metricVariants]="metricVariants"
      [hyperParams]="hyperParams"
      [tableCols]="tableCols"
      [isLoading]="isMetricsLoading"
      (selectedMetricToShow)="selectedMetricToShow.emit($event)"
      (selectedHyperParamToShow)="selectedHyperParamToShow.emit($event)"
      (selectedTableColsChanged)="selectedTableColsChanged.emit($event)"
      (getMetricsToDisplay)="getMetricsToDisplay.emit($event)"
      (removeColFromList)="removeColFromList.emit($event)"
      (clearSelection)="clearSelection.emit()"
    ></sm-experiment-custom-cols-menu>
    <sm-refresh-button
      [allowAutoRefresh]="true"
      (setAutoRefresh)="setAutoRefresh.emit($event)"
    >
    </sm-refresh-button>
  </div>
</div>
